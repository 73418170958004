import React from "react";
import PropTypes from "prop-types";

import EnvironmentGeneralSettings from "Containers/EnvironmentGeneralSettings";

class EnvironmentSettingsGeneral extends React.Component {
  render() {
    return <EnvironmentGeneralSettings {...this.props.params} />;
  }
}

EnvironmentSettingsGeneral.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    environmentId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired
  })
};

export default EnvironmentSettingsGeneral;
