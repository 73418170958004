import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import Button from "Components/Button";
import Error from "Components/Error";
import InputField from "Components/fields/InputField";

const ListFieldLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  .add-button {
    span {
      display: inline-block;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  button {
    padding: 0 16px;
  }
  .error-alert {
    border: none;
    padding: 4px 0 0;
    .icon {
      display: none;
    }
    .message {
      margin-left: 0;
      font-size: 13px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  @media (min-width: 600px) {
    .current-values {
      display: flex;
      > div {
        margin-right: 10px;
      }
    }
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  flex-shrink: 0;
  div + div {
    margin-left: 16px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  div + div {
    margin-bottom: 20px;
  }
`;

const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span + input {
    margin-left: 16px;
  }
`;

const onChange = (index, field, value, onChange) => event =>
  onChange(event.target.value, field, index);

const getInput = (field, index, value, onChangeCallback, enabled, error) => (
  <div key={`${field}-${index}`}>
    <InputLayout className="field list-field">
      <InputField
        id={`${field}-${index}`}
        name={`${field}-${index}`}
        onChange={onChange(index, field, value, onChangeCallback)}
        value={value[field] || ""}
        disabled={!enabled}
        aria-disabled={!enabled}
        label={field}
      />
    </InputLayout>
    {error && <Error>{error}</Error>}
  </div>
);

// eslint-disable-next-line
const ListField = ({
  fields = [],
  values = [],
  onChange,
  onAdd,
  addButtonText = "add",
  direction = "row",
  editedLine,
  editLine,
  removeLine,
  enabled = true,
  id,
  errors,
  generalError,
  className,
  intl
}) => {
  const LayoutComponent = direction === "row" ? Line : Column;

  return (
    <ListFieldLayout className={className}>
      {generalError && <Error>{generalError}</Error>}
      {values.map((value, index) => (
        <LayoutComponent
          className={`${direction}${
            editedLine !== index ? " list-item" : " editing"
          }`}
          key={`line-${index}`}
          direction={direction}
        >
          {fields.map(
            (field, fieldIndex) =>
              editedLine === index ? (
                getInput(
                  field,
                  index,
                  value,
                  onChange,
                  enabled,
                  errors[index] && errors[index][field]
                )
              ) : (
                <div
                  className="current-values"
                  key={`${id}-key-name-${index}-${fieldIndex}`}
                >
                  <div
                    className="label"
                    id={`${id}-key-name-${index}-${fieldIndex}`}
                  >
                    {field}:
                  </div>
                  <span id={`${id}-key-value-${index}-${fieldIndex}`}>
                    {value[field]}
                  </span>
                  {errors[index] && <Error>{errors[index][field]}</Error>}
                </div>
              )
          )}
          {editedLine !== index &&
            enabled && (
              <Button
                id={`${id}-edit-${index}`}
                type="button"
                className="secondary"
                aria-label={intl.formatMessage({ id: "edit" })}
                onClick={() => editLine(index)}
              >
                {intl.formatMessage({ id: "edit" })}
              </Button>
            )}
          <Button
            id={`${id}-delete-${index}`}
            type="button"
            className="secondary"
            aria-label={intl.formatMessage({ id: "remove" })}
            onClick={() => removeLine(index)}
          >
            {intl.formatMessage({ id: "remove" })}
          </Button>
        </LayoutComponent>
      ))}
      <div>
        {enabled && (
          <Button
            id={`${id}-add`}
            type="button"
            aria-label={addButtonText}
            onClick={onAdd}
            className="add-button"
          >
            + <span>{addButtonText}</span>
          </Button>
        )}
      </div>
    </ListFieldLayout>
  );
};

ListField.propTypes = {
  fields: PropTypes.array,
  values: PropTypes.array,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  editLine: PropTypes.func,
  removeLine: PropTypes.func,
  addButtonText: PropTypes.string,
  direction: PropTypes.string,
  editedLine: PropTypes.number,
  className: PropTypes.string,
  generalError: PropTypes.string,
  id: PropTypes.string,
  enabled: PropTypes.bool,
  intl: PropTypes.object
};

export default injectIntl(ListField);
