import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { Map } from "immutable";
import { LiveMessage } from "react-aria-live";

import {
  updateEnvironment,
  toggleEnvironmentActivation
} from "Reducers/environment";

import { httpStatusDisplay } from "Libs/utils";

import InputField from "Components/fields/InputField";
import ToggleSwitch from "Components/ToggleSwitch";
import CheckboxField from "Components/fields/CheckboxField";
import Heading2 from "Components/styleguide/Heading2";
import Heading6 from "Components/styleguide/Heading6";
import SettingLine from "Components/SettingLine";
import EnvironmentSettingWrapper from "Components/EnvironmentSettingWrapper";
import EnvironmentSettingsLayout from "Components/EnvironmentSettingsLayout";
import EnvironmentSettingsHttpAccess from "Components/EnvironmentSettingsHttpAccess";
import CopyableArea from "Components/CopyableArea";
import AlertIcon from "Icons/AlertIcon";
import Dropdown from "Components/Dropdown";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import ModalConfirmDelete from "Components/ModalConfirmDelete";

const TextWrapper = styled.div`
  margin-bottom: 32px;
  font-size: 14px;
  h4 {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #dedede;
  }
  .description {
    color: #4a495e;
    font-size: 15px;
  }
  ul {
    margin-bottom: 32px;
    li {
      margin-bottom: 10px;
    }
  }

  pre {
    border: none;
    background: #1a192b;
    line-height: 40px;
    padding: 0 15;
    margin-right: 0;
    color: #fff;
  }
  .copy-to-clipboard {
    border: none;
    background: #1a192b;
    line-height: 40px;
    height: 40px;
    padding: 0 15px;
    border-radius: 0;
    font: 0/0 none;
    svg > g > g {
      fill: #fff;
    }
    &:hover svg > g > g {
      fill: rgb(22, 100, 255);
    }
  }
  .copied-message {
    border-radius: 0;
  }
`;

const InfoLayout = styled.div`
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
  .cap {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const WarningText = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #38485e;
  font-size: 13px;
  svg {
    margin-right: 8px;
  }
`;

const AgreeWrapper = styled.div`
  .field {
    width: 100%;
  }
`;

const DropdownWrapper = styled.div`
  .Select-control {
    border: none;
    background: #f7f7f7 !important;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    width: 300px !important;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .dropdown > div {
    display: block;
  }
  .highlight {
    bottom: 21px;
  }
`;

class EnvironmentGeneralSettings extends React.Component {
  constructor(props) {
    super(props);

    this.toggleStatus = this.toggleStatus.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this);
    this.openUpdatePlan = this.openUpdatePlan.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleConfirmCheckbox = this.handleConfirmCheckbox.bind(this);
    this.onEnvironmentTitleChange = this.onEnvironmentTitleChange.bind(this);
    this.onEnvironmentParentChange = this.onEnvironmentParentChange.bind(this);
    this.onEmailSendingChange = this.onEmailSendingChange.bind(this);
    this.onIndexingChange = this.onIndexingChange.bind(this);
    this.onHttpAccessControlChange = this.onHttpAccessControlChange.bind(this);
    this.onLoginChange = this.onLoginChange.bind(this);
    this.onIpTextAreaChange = this.onIpTextAreaChange.bind(this);
    this.updateEnvironment = this.updateEnvironment.bind(this);
    this.editLine = this.editLine.bind(this);
    this.cancel = this.cancel.bind(this);
    this.resetAccessChanged = this.resetAccessChanged.bind(this);
    this.getTitleBar = this.getTitleBar.bind(this);
    this.acceptDeleteToggle = this.acceptDeleteToggle.bind(this);
    this.redirectToPlan = this.redirectToPlan.bind(this);
    this.state = {
      environmentStatus: true,
      isModalOpen: false,
      confirmDelete: false,
      confirmDeleteModal: false,
      updatePlanModal: false,
      http_access: {
        is_enabled: false,
        addresses: [],
        basic_auth: {}
      },
      enable_smtp: false,
      restrict_robots: false,
      editedLine: "",
      accessChanged: false,
      acceptDelete: false
    };
  }

  componentDidMount() {
    if (this.props.environment) {
      const basic_auth = this.props.environment.http_access.basic_auth;
      this.setState({
        logins: Object.keys(basic_auth).map(k => ({
          login: k,
          password: basic_auth[k]
        })),
        environmentTitle: this.props.environment.title,
        environmentParent: this.props.environment.parent,
        environmentStatus:
          this.props.environment.isActive && this.props.environment.isActive(),
        http_access: {
          is_enabled: this.props.environment.http_access.is_enabled,
          addresses: this.props.environment.http_access.addresses,
          basic_auth
        },
        enable_smtp: this.props.environment.enable_smtp,
        restrict_robots: this.props.environment.restrict_robots,
        titleChange: false,
        isChanged: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.environment !== nextProps.environment) {
      const basic_auth = nextProps.environment.http_access.basic_auth;
      this.setState({
        logins: Object.keys(basic_auth).map(k => ({
          login: k,
          password: basic_auth[k]
        })),
        environmentTitle: nextProps.environment.title,
        environmentParent: nextProps.environment.parent,
        environmentStatus:
          nextProps.environment.isActive && nextProps.environment.isActive(),
        http_access: {
          is_enabled: nextProps.environment.http_access.is_enabled,
          addresses: nextProps.environment.http_access.addresses,
          basic_auth
        },
        enable_smtp: nextProps.environment.enable_smtp,
        restrict_robots: nextProps.environment.restrict_robots,
        titleChange: false,
        isChanged: false,
        isModalLeaveOpen: false
      });
    }
  }

  toggleStatus() {
    this.setState({
      environmentStatus: !this.state.environmentStatus
    });
  }

  saveStatus() {
    this.props.environment.status !== "inactive" && !this.state.isModalOpen
      ? this.setState({ isModalOpen: true })
      : this.props.toggleEnvironmentActivation();
  }

  openModal(modalContent) {
    this.setState({
      isModalOpen: true,
      modalContent
    });
  }

  openDeleteConfirm() {
    this.setState({
      confirmDeleteModal: true
    });
  }

  openUpdatePlan() {
    this.setState({
      updatePlanModal: true
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      isModalLeaveOpen: false,
      confirmDeleteModal: false,
      updatePlanModal: false
    });
  }

  handleConfirmCheckbox() {
    this.setState({ confirmDelete: !this.state.confirmDelete });
  }

  onEnvironmentTitleChange(e) {
    const environmentTitle = e.target.value;
    this.setState({
      environmentTitle,
      titleChange:
        environmentTitle === this.state.environmentTitle ? false : true,
      isChanged: true
    });
  }

  onEnvironmentParentChange({ value }) {
    this.setState({
      environmentParent: value,
      isChanged: true
    });
  }

  onChange(name, value) {
    this.setState({
      [name]: value
    });
  }

  updateEnvironment() {
    const {
      environmentTitle,
      environmentParent,
      http_access,
      enable_smtp,
      restrict_robots
    } = this.state;

    this.props.updateEnvironment({
      title: environmentTitle,
      parent: environmentParent,
      http_access,
      enable_smtp,
      restrict_robots
    });
    this.setState({
      editedLine: "",
      isChanged: false
    });
  }

  onEmailSendingChange() {
    this.onChange("enable_smtp", !this.state.enable_smtp, true);
    setTimeout(() => {
      this.updateEnvironment();
    }, 500);
  }
  onIndexingChange() {
    this.setState({
      restrict_robots: !this.state.restrict_robots
    });
    setTimeout(() => {
      this.updateEnvironment();
    }, 500);
  }
  onHttpAccessControlChange() {
    this.setState({
      http_access: {
        is_enabled: !this.state.http_access.is_enabled,
        addresses: this.props.environment.http_access.addresses || [],
        basic_auth: this.props.environment.http_access.basic_auth || {}
      },
      accessChanged: true,
      isChanged: true
    });
  }
  onIpTextAreaChange(event) {
    const value = event.target.value;
    this.setState(prevState => ({
      ipText: value,
      http_access: {
        ...prevState.http_access,
        addresses: this.textToIp(value) || []
      },
      accessChanged: true,
      isChanged: true
    }));
  }
  textToIp(text) {
    if (!text) {
      return false;
    }
    const lines = text.split("\n");

    return lines
      .filter(function(line) {
        return line != "";
      })
      .map(splitedLine => {
        const columns = splitedLine.split(" ");
        return {
          address: columns[0],
          permission: columns[1]
        };
      });
  }

  onLoginChange(logins) {
    this.setState(prevState => ({
      logins,
      http_access: {
        ...prevState.http_access,
        basic_auth: logins.reduce((logins, auth) => {
          logins[auth.login] = auth.password;
          return logins;
        }, {})
      },
      accessChanged: true,
      isChanged: true
    }));
  }

  resetAccessChanged() {
    this.setState({
      accessChanged: false
    });
  }

  editLine(index) {
    if (this.state.isChanged && !this.state.isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true,
        newLine: index
      });
    } else {
      this.state.editedLine === index
        ? this.setState({
            editedLine: "",
            titleChange:
              this.props.environment.title === this.state.environmentTitle
                ? false
                : true,
            isChanged: false,
            isModalLeaveOpen: false
          })
        : this.setState({
            editedLine: index,
            titleChange:
              this.props.environment.title === this.state.environmentTitle
                ? false
                : true,
            isChanged: false,
            isModalLeaveOpen: false
          });
    }
  }

  acceptDeleteToggle() {
    this.setState({
      acceptDelete: !this.state.acceptDelete
    });
  }

  cancel() {
    const basic_auth = this.props.environment.http_access.basic_auth;
    this.setState({
      logins: Object.keys(basic_auth).map(k => ({
        login: k,
        password: basic_auth[k]
      })),
      environmentTitle: this.props.environment.title,
      environmentParent: this.props.environment.parent,
      environmentStatus:
        this.props.environment.isActive && this.props.environment.isActive(),
      http_access: {
        is_enabled: this.props.environment.http_access.is_enabled,
        addresses: this.props.environment.http_access.addresses,
        basic_auth
      },
      enable_smtp: this.props.environment.enable_smtp,
      restrict_robots: this.props.environment.restrict_robots,
      titleChange: false
    });
  }

  getTitleBar(index, environment, intl) {
    switch (index) {
      case "basic":
        return (
          <InfoLayout>
            Environment name{" "}
            <strong id={`${index}-title`}>{environment.title}</strong>
          </InfoLayout>
        );
      case "status":
        return (
          <InfoLayout>
            <div id={`${index}-title`}>
              Status is{" "}
              <strong className="cap">
                {environment.status == "dirty"
                  ? "building"
                  : environment.status}
              </strong>
            </div>
          </InfoLayout>
        );
      case "email":
        return (
          <InfoLayout>
            <div id={`${index}-title`}>
              Outgoing emails are{" "}
              <strong>{environment.enable_smtp ? "On" : "Off"}</strong>
            </div>
          </InfoLayout>
        );
      case "indexing":
        return (
          <InfoLayout>
            <div id={`${index}-title`}>
              {intl.formatMessage({ id: "is_hidden" })}{" "}
              <strong>{environment.restrict_robots ? "On" : "Off"}</strong>
            </div>
          </InfoLayout>
        );
      case "httpaccess":
        return (
          <InfoLayout>
            <div id={`${index}-title`}>
              HTTP access control is{" "}
              <strong>{httpStatusDisplay(environment) ? "On" : "Off"}</strong>
            </div>
          </InfoLayout>
        );
    }
  }

  redirectToPlan(project) {
    if (project && project.data && project.data.plan_uri) {
      window.location = project.data.plan_uri;
    }
  }

  render() {
    const {
      intl,
      environments = [],
      environment = {},
      project,
      istoggleActivationLoading,
      isUpdateLoading,
      errors
    } = this.props;

    let currentStatus =
      this.props.environment &&
      this.props.environment.isActive &&
      this.props.environment.isActive();

    if (!environment) {
      return false;
    }

    const { environmentTitle, environmentParent, http_access } = this.state;

    const parentEnvironments = environments
      .valueSeq()
      .toJS()
      .map(env => ({
        value: env.id,
        label: env.title
      }))
      .filter(item => item.value !== environment.id);

    const activeEnvironments = environments
      .valueSeq()
      .toJS()
      .map(env => ({
        id: env.id,
        status: env.status
      }))
      .filter(item => item.status !== "inactive").length;
    const environmentLimit =
      project && project.subscription && project.subscription.environments + 1;

    const ipText =
      this.state.ipText ||
      (http_access.addresses &&
        http_access.addresses
          .map(a => `${a.address} ${a.permission}\n`)
          .join("")) ||
      [];
    const basicAuth = this.state.logins;

    const settingsOptions = [
      "basic",
      "status",
      "email",
      "indexing",
      "httpaccess"
    ];
    return (
      <EnvironmentSettingsLayout>
        <LiveMessage
          message={`${environment.title} basic settings`}
          aria-live="polite"
        />
        <Heading2 id="settings-heading">
          {intl.formatMessage({ id: "general" })}
        </Heading2>
        <section aria-labelledby="settings-heading">
          {settingsOptions.map(index => {
            return (
              <SettingLine
                key={`${index}-read`}
                id={`settings-${index}`}
                info={this.getTitleBar(index, environment, intl)}
                openText={intl.formatMessage({ id: "edit" })}
                isOpen={this.state.editedLine === index}
                onClick={() => this.editLine(index)}
              >
                {this.state.editedLine === index &&
                  this.state.editedLine === "basic" && (
                    <EnvironmentSettingWrapper
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      save={this.updateEnvironment}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      cancel={() => this.cancel()}
                      hideButtons={!this.state.titleChange}
                    >
                      <p className="description">
                        Configure your environment name and it's parent
                        environment.
                      </p>
                      <div className="inline-fields">
                        <div className="field field-environment-name">
                          <InputField
                            id="environment-name-input"
                            label={intl.formatMessage({
                              id: "environment_name"
                            })}
                            value={environmentTitle}
                            onChange={this.onEnvironmentTitleChange}
                            enabled={
                              environment.hasPermission &&
                              environment.hasPermission("#edit")
                            }
                          />
                        </div>
                        <div className="field field-environment-parent">
                          <DropdownWrapper>
                            <Dropdown
                              id="environment-parent-options"
                              className="environment-parent"
                              label={intl.formatMessage({
                                id: "parent_environment"
                              })}
                              options={parentEnvironments}
                              onChange={this.onEnvironmentParentChange}
                              defaultValue={{
                                value: environmentParent,
                                label: environmentParent
                              }}
                              searchable={false}
                              clearable={false}
                              error={this.props.errors.type}
                              disabled={
                                environment.hasPermission &&
                                !environment.hasPermission("#edit")
                              }
                              aria-disabled={
                                environment.hasPermission &&
                                !environment.hasPermission("#edit")
                              }
                            />
                          </DropdownWrapper>
                        </div>
                      </div>
                    </EnvironmentSettingWrapper>
                  )}
                {this.state.editedLine === index &&
                  this.state.editedLine === "status" && (
                    <EnvironmentSettingWrapper
                      title={`Status is ${
                        currentStatus ? "active" : "inactive"
                      }`}
                      close={() => this.editLine("")}
                      isUpdateLoading={istoggleActivationLoading}
                      save={e => {
                        if (currentStatus) {
                          if (this.state.acceptDelete) {
                            e.preventDefault();
                            this.openDeleteConfirm();
                          }
                        } else {
                          if (environmentLimit <= activeEnvironments) {
                            e.preventDefault();
                            this.openUpdatePlan();
                          } else {
                            this.props.toggleEnvironmentActivation();
                          }
                        }
                      }}
                      saveText={currentStatus ? "Delete" : "Activate"}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={
                        !currentStatus
                          ? !currentStatus
                          : this.state.acceptDelete
                      }
                      hideButtons={environment.is_main}
                    >
                      {environment.is_main ? (
                        <React.Fragment>
                          <p className="description">
                            {intl.formatMessage({
                              id: "status_active_description"
                            })}
                          </p>
                          <p className="description">
                            This is the main environment. This cannot be deleted
                            or disabled.
                          </p>
                        </React.Fragment>
                      ) : currentStatus ? (
                        <TextWrapper>
                          <p className="description">
                            {intl.formatMessage({
                              id: "status_active_description"
                            })}
                          </p>
                          <hr className="full-width" />
                          <Heading6>
                            {intl.formatMessage({ id: "delete_environment" })}
                          </Heading6>
                          <WarningText>
                            <AlertIcon color="#ff8359" />
                            {intl.formatMessage({
                              id: "status_active_delete_warning"
                            })}
                          </WarningText>
                          <p className="description">
                            {intl.formatMessage({
                              id: "status_active_delete_description"
                            })}
                          </p>
                          <ul className="description">
                            <li>
                              {intl.formatMessage({
                                id: "status_active_delete_bullet_1"
                              })}
                            </li>
                            <li>
                              {intl.formatMessage({
                                id: "status_active_delete_bullet_2"
                              })}
                            </li>
                            <li>
                              {intl.formatMessage({
                                id: "status_active_delete_bullet_3"
                              })}
                            </li>
                            <li>
                              {intl.formatMessage({
                                id: "status_active_delete_bullet_4"
                              })}
                            </li>
                          </ul>
                          <hr />
                          <AgreeWrapper>
                            <CheckboxField
                              label={intl.formatMessage({
                                id: "status_active_delete_verify"
                              })}
                              id="verify"
                              value={this.state.acceptDelete}
                              onChange={this.acceptDeleteToggle}
                              forId="verify-delete"
                              required={true}
                            />
                          </AgreeWrapper>
                          <ModalConfirmDelete
                            isOpen={this.state.confirmDeleteModal}
                            closeModal={this.closeModal}
                            deleteFunction={() => {
                              this.props.toggleEnvironmentActivation();
                              this.closeModal();
                            }}
                            title="Delete environment?"
                            body={
                              <span>
                                Are you sure you want to permanently delete the
                                environment <strong>{environment.title}</strong>{" "}
                                from <strong>{project.title}</strong>?
                              </span>
                            }
                            deleteText="Yes, delete"
                          />
                        </TextWrapper>
                      ) : (
                        <TextWrapper>
                          <p className="description">
                            {intl.formatMessage({
                              id: "status_inactive_description"
                            })}
                          </p>
                          <p className="description">
                            {intl.formatMessage({
                              id: "delete_git_branch_text"
                            })}
                          </p>
                          <CopyableArea
                            id="environment-delete-branch"
                            content={`git push platform --delete ${
                              environment.name
                            }`}
                            singleLine={true}
                          >
                            git push platform --delete {environment.name}
                          </CopyableArea>
                          <hr className="full-width" />
                          <Heading6>
                            {intl.formatMessage({
                              id: "activate_environment"
                            })}
                          </Heading6>
                          <p className="description">
                            {intl.formatMessage({
                              id: "status_activate_description"
                            })}
                          </p>
                          <ModalConfirmDelete
                            isOpen={this.state.updatePlanModal}
                            closeModal={this.closeModal}
                            deleteFunction={() => {
                              if (project.data._links["#subscription_change"]) {
                                this.redirectToPlan(project);
                                this.closeModal();
                              } else {
                                this.closeModal();
                              }
                            }}
                            title="Environment limit reached"
                            body={
                              <span>
                                {project.data._links["#subscription_change"]
                                  ? "To enable this environment you will need to upgrade your plan settings as you have used all of your allocated environments."
                                  : "To enable this environment you will need to upgrade your plan settings as you have used all of your allocated environments.  Please contact your project owner to upgrade your plan settings."}
                              </span>
                            }
                            deleteText={
                              project.data._links["#subscription_change"]
                                ? "Upgrade plan"
                                : "Okay"
                            }
                            cancelButton={
                              project.data._links["#subscription_change"]
                                ? true
                                : false
                            }
                            cancelText="Cancel"
                          />
                        </TextWrapper>
                      )}
                    </EnvironmentSettingWrapper>
                  )}
                {this.state.editedLine === index &&
                  this.state.editedLine === "email" && (
                    <EnvironmentSettingWrapper
                      title="Change email settings"
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={
                        this.state.enable_smtp !==
                        this.props.environment.enable_smtp
                      }
                      cancel={() => this.cancel()}
                    >
                      <ToggleSwitch
                        id="environment-is-sending-email-switch"
                        title={intl.formatMessage({ id: "is_sending_email" })}
                        description={intl.formatMessage({
                          id: "is_sending_email.description"
                        })}
                        onClick={this.onEmailSendingChange}
                        value={this.state.enable_smtp}
                        enabled={
                          environment.hasPermission &&
                          environment.hasPermission("#edit")
                        }
                      />
                    </EnvironmentSettingWrapper>
                  )}
                {this.state.editedLine === index &&
                  this.state.editedLine === "indexing" && (
                    <EnvironmentSettingWrapper
                      title="Change search settings"
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={
                        this.state.restrict_robots !==
                        this.props.environment.restrict_robots
                      }
                      cancel={() => this.cancel()}
                    >
                      <ToggleSwitch
                        id="environment-is-hidden-switch"
                        title={intl.formatMessage({ id: "is_hidden" })}
                        description={intl.formatMessage({
                          id: "is_hidden.description"
                        })}
                        onClick={this.onIndexingChange}
                        value={this.state.restrict_robots}
                        enabled={
                          environment.hasPermission &&
                          environment.hasPermission("#edit")
                        }
                      />
                    </EnvironmentSettingWrapper>
                  )}
                {this.state.editedLine === index &&
                  this.state.editedLine === "httpaccess" && (
                    <EnvironmentSettingWrapper
                      title="Change access settings"
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      save={() => {
                        this.updateEnvironment();
                        this.resetAccessChanged();
                      }}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={this.state.accessChanged}
                      cancel={() => this.cancel()}
                    >
                      <ToggleSwitch
                        id="environment-is-controlled-switch"
                        title={intl.formatMessage({ id: "is_controlled" })}
                        description={intl.formatMessage({
                          id: "is_controlled.description"
                        })}
                        onClick={this.onHttpAccessControlChange}
                        value={this.state.http_access.is_enabled}
                        enabled={
                          environment.hasPermission &&
                          environment.hasPermission("#edit")
                        }
                      />
                      {this.state.http_access.is_enabled && (
                        <React.Fragment>
                          <hr className="full-width" />
                          <EnvironmentSettingsHttpAccess
                            errors={errors}
                            environment={environment}
                            projectId={this.props.projectId}
                            basicAuth={basicAuth}
                            ipText={ipText}
                            onLoginChange={this.onLoginChange}
                            onIpTextAreaChange={this.onIpTextAreaChange}
                          />
                        </React.Fragment>
                      )}
                    </EnvironmentSettingWrapper>
                  )}
              </SettingLine>
            );
          })}
          <ModalConfirmLeaveForm
            isOpen={this.state.isModalLeaveOpen}
            closeModal={this.closeModal}
            continueFunction={() => {
              this.editLine(this.state.newLine);
            }}
            cancelFunction={this.closeModal}
          />
        </section>
      </EnvironmentSettingsLayout>
    );
  }
}

EnvironmentGeneralSettings.propTypes = {
  intl: PropTypes.object,
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
  environmentId: PropTypes.string,
  updateEnvironment: PropTypes.func,
  toggleEnvironmentActivation: PropTypes.func,
  environment: PropTypes.object,
  parent: PropTypes.object,
  environments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  istoggleActivationLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  errors: PropTypes.object,
  project: PropTypes.object
};

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn([
    "data",
    props.organizationId,
    props.projectId,
    props.environmentId
  ]);
  const project = state.project.getIn(
    ["data", props.organizationId, props.projectId],
    {}
  );
  return {
    environment,
    project,
    parent:
      environment &&
      state.environment.getIn(
        ["data", props.organizationId, props.projectId, environment.parent],
        new Map()
      ),
    environments: state.environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    errors: state.environment.getIn(["errors", "detail"], new Map()),
    isLoading: state.environment.get("loading"),
    isUpdateLoading: state.environment.get("updateLoading"),
    istoggleActivationLoading: state.environment.get("toggleActivationLoading")
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  updateEnvironment: data =>
    dispatch(
      updateEnvironment(
        props.organizationId,
        props.projectId,
        props.environmentId,
        data
      )
    ),
  toggleEnvironmentActivation: () =>
    dispatch(
      toggleEnvironmentActivation(
        props.organizationId,
        props.projectId,
        props.environmentId
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentGeneralSettings));
