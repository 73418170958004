import styled from "styled-components";

export default styled.div`
  margin: 0 0 24px;
  width: 100%;
  box-sizing: border-box;
  .field {
    margin-bottom: 24px;
  }
  .login-list-field {
    width: 100%;
    .row {
      width: 100%;
      margin: 0 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .current-values {
        width: 264px;
      }
      &.editing {
        align-items: flex-start;
        .field {
          margin-bottom: 0;
          width: 264px;
        }
        button {
          margin-top: 26px;
        }
      }
      button {
        margin-left: 16px;
      }
    }
  }
  .access-ips {
    display: flex;
    flex-direction: row;
    align-items: top;
    textarea {
      min-width: 50%;
      width: auto;
    }
  }
  .row {
    line-height: 40px;
  }
  input {
    height: 40px;
    line-height: 40px;
    border: none;
    background: #f7f7f7;
  }
  h6 {
    margin-top: 24px;
  }
`;
