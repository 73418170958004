import React from "react";
import PropTypes from "prop-types";

const AlertIcon = ({ color = "#ff8359" }) => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill-target"
      d="M18.3818 13.9657C19.1353 15.299 18.1721 16.9498 16.6406 16.9498H2.00293C0.471476 16.9498 -0.491751 15.299 0.261766 13.9657L7.58062 1.01595C8.3462 -0.338646 10.2974 -0.33865 11.0629 1.01594L18.3818 13.9657ZM8.32275 8.94977C8.32275 8.34977 8.72275 7.94977 9.32275 7.94977C9.92275 7.94977 10.3228 8.34977 10.3228 8.94977V13.9498C10.3228 14.5498 9.92275 14.9498 9.32275 14.9498C8.72275 14.9498 8.32275 14.5498 8.32275 13.9498V8.94977ZM8.32275 5.94977C8.32275 5.34977 8.72275 4.94977 9.32275 4.94977C9.92275 4.94977 10.3228 5.34977 10.3228 5.94977C10.3228 6.54977 9.92275 6.94977 9.32275 6.94977C8.72275 6.94977 8.32275 6.54977 8.32275 5.94977Z"
      fill={color}
    />
  </svg>
);

AlertIcon.propTypes = {
  color: PropTypes.string
};

export default AlertIcon;
