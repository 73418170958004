import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import HttpAccessControlInputs from "Components/HttpAccessControlInputs";
import LoginListField from "Containers/LoginListField";
import Label from "Components/fields/Label";
import Heading6 from "Components/styleguide/Heading6";

const IpTextArea = styled.textarea`
  width: 200px;
  min-height: 50px;
  background: #f7f7f7;
  border: none;
  resize: none;
  font-size: 14px;
  padding: 15px;
`;

class EnvironmentSettingsHttpAccess extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl,
      errors,
      basicAuth,
      ipText,
      projectId,
      environment,
      onLoginChange,
      onIpTextAreaChange
    } = this.props;
    return (
      <HttpAccessControlInputs>
        <div className="field">
          <Heading6>{intl.formatMessage({ id: "logins" })}</Heading6>
          <LoginListField
            errors={errors.toJS()}
            basicAuth={basicAuth}
            onChange={onLoginChange}
            projectId={projectId}
            environmentId={environment.id}
            enabled={
              environment.hasPermission && environment.hasPermission("#edit")
            }
          />
        </div>
        <hr />
        <Heading6>{intl.formatMessage({ id: "ip_addresses" })}</Heading6>
        <div className="description">
          {intl.formatMessage({
            id: "ip_addresses_description1"
          })}
          <br />
          {intl.formatMessage({
            id: "ip_addresses_description2"
          })}
          <br />
          {intl.formatMessage({ id: "for_example" })}:{" "}
          <code>123.45.67.8 deny</code> {intl.formatMessage({ id: "or" })}
          <code>123.45.67.1/32 allow</code>
        </div>
        <div className="field">
          <Label htmlFor="ip-text-area">
            {intl.formatMessage({ id: "ip_addresses" })}
          </Label>
          <div className="access-ips">
            <IpTextArea
              id="ip-text-area"
              name="ip-text-area"
              value={ipText}
              onChange={onIpTextAreaChange}
              disabled={
                !environment.hasPermission && environment.hasPermission("#edit")
              }
              aria-disabled={
                !environment.hasPermission && environment.hasPermission("#edit")
              }
            />
          </div>
        </div>
      </HttpAccessControlInputs>
    );
  }
}

EnvironmentSettingsHttpAccess.propTypes = {
  intl: PropTypes.object,
  errors: PropTypes.object,
  environment: PropTypes.object,
  projectId: PropTypes.string,
  basicAuth: PropTypes.array,
  ipText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onLoginChange: PropTypes.func,
  onIpTextAreaChange: PropTypes.func
};

export default injectIntl(EnvironmentSettingsHttpAccess);
